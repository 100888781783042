import axios from 'src/_services/axios-configs'

const state = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')) : null,
  currentCompany: JSON.parse(localStorage.getItem('currentCompany')) ? JSON.parse(localStorage.getItem('currentCompany')) : null,
  userAccount: JSON.parse(localStorage.getItem('userAccount')) ? JSON.parse(localStorage.getItem('userAccount')) : null,
  userEmail: '',
  userPassword: '',
  userDetails: {}
}

const getters = {
  currentUser (state) {
    return state.currentUser
  },
  currentCompany (state) {
    return state.currentCompany
  },
  userAccount (state) {
    return state.userAccount
  },
  getEmail (state) {
    return state.userEmail
  },
  getPassword (state) {
    return state.userPassword
  },
  userDetails: s => s.userDetails
}

const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user
  },
  setCurrentCompany (state, company) {
    state.currentCompany = company
  },
  setUserAccount (state, account) {
    state.userAccount = account
  },
  setEmail (state, email) {
    state.userEmail = email
  },
  setPassword (state, password) {
    state.userPassword = password
  },
  setDetails (state, payload) {
    state.userDetails = payload
  },
  setThumbnail (state) {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/png'
    newFavicon.href = state.userDetails.thumbnailLink || '/favicon.ico'
    document.head.appendChild(newFavicon)
  },
  setTitle (state, title) {
    console.log(window.location.hostname, 'window.location.hostname')
    const domain = state.userDetails.custom_domain || window.location.hostname.replace('portal.', '').replace('test.', '').replace('testportal.', '')
    if (!title) title = state.currentCompany && state.currentCompany.is_whitelabel ? state.currentCompany.accountName : domain
    document.title = title || ' '
  }
}

const actions = {
  setCurrentUser ({ commit }, user) {
    commit('setCurrentUser', user)
  },
  setCurrentCompany ({ commit, dispatch }, company) {
    commit('setCurrentCompany', company)
    if (company && company.is_whitelabel) dispatch('loadDetails', company.custom_domain)
  },
  setUserAccount ({ commit }, account) {
    commit('setUserAccount', account)
  },
  setEmail ({ commit }, email) {
    commit('setEmail', email)
  },
  setPassword ({ commit }, password) {
    commit('setPassword', password)
  },
  login ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', data)
        .then(res => {
          if (!res.data.user) resolve(res)
          dispatch('setCurrentUser', res.data.user)
          dispatch('setUserAccount', res.data.account)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (res.data.user) {
            localStorage.setItem('currentUser', JSON.stringify(res.data.user))
          } else {
            localStorage.removeItem('currentUser')
          }
          localStorage.setItem('userAccount', JSON.stringify(res.data.account ? res.data.account : {}))
          localStorage.setItem('sipAccess', JSON.stringify({
            sip_username: res.data.user.sipUsername
            // password: list.form.password
          }))

          resolve(res)
        })
        .catch(err => {
          reject(err.response)
        })
    })
  },
  registration ({ dispatch }, { form }) {
    return new Promise((resolve, reject) => {
      const data = {
        email: form.email,
        password: form.password,
        rePassword: form.rePassword,
        firstName: form.firstName,
        lastName: form.lastName,
        accountName: form.companyName ? form.companyName : null,
        userPhoneNumber: form.userPhone ? form.userPhone : null,
        is_free: form.is_free,
        slug: form.companySlug
      }

      axios.post('/auth/signup', data)
        .then(res => {
          /* localStorage.setItem('currentUser', JSON.stringify(res.data.user))
          localStorage.setItem('currentCompany', JSON.stringify(res.data.account))

          dispatch('setCurrentUser', res.data.user)
          dispatch('setCurrentCompany', res.data.account) */

          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  confirmEmail (_, token) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/email-confirmation', { token })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  reSendConfirmation (_, email) {
    return new Promise((resolve, reject) => {
      axios.post(`/auth/resend-confirmation/${email}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changePassword (_, data) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/password-recover', data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  resetPassword (_, list) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/password-reset', { email: list.form.email })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  logout ({ dispatch }) {
    try {
      // remove USER and COMPANY from local storage to log user out
      localStorage.removeItem('currentUser')
      localStorage.removeItem('sipAccess')
      localStorage.removeItem('userAccount')
      localStorage.removeItem('currentCompany')

      dispatch('setCurrentUser', null)
      dispatch('setCurrentCompany', null)
    } catch (err) {
      throw new Error(err)
    }
  },
  getAccountData ({ dispatch }, role) {
    return new Promise((resolve, reject) => {
      const url = role === 'account_admin' ? '/account' : '/user'

      axios.get(url)
        .then(res => {
          localStorage.setItem('currentCompany', JSON.stringify(res.data))
          dispatch('setCurrentCompany', res.data)

          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAccountUserData ({ dispatch }, user) {
    return new Promise((resolve, reject) => {
      axios.get('/user')
        .then(res => {
          const _user = {
            ...res.data,
            ...user
          }
          dispatch('setCurrentUser', _user)
          localStorage.setItem('currentUser', JSON.stringify(_user))
          localStorage.setItem('sipAccess', JSON.stringify({
            sip_username: _user.sipUsername
          }))
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteAccount ({ state }) {
    return new Promise((resolve, reject) => {
      axios.patch(`/user/${state.currentUser.uuid}/active/false`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changeAccountPassword (_, data) {
    return new Promise((resolve, reject) => {
      axios.patch('/user/change/password', data)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getSipToken () {
    return new Promise((resolve, reject) => {
      axios.post('/auth/sip_token')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  isSubdomainAvailable (_, slug) {
    return new Promise((resolve, reject) => {
      axios.get(`/public/is_subdomain_available/${slug}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  loadDetails ({ commit }, domain) {
    if (!domain) return
    return new Promise((resolve, reject) => {
      const url = `/public/account/details/${domain}`
      axios.get(url)
        .then(res => {
          commit('setDetails', res.data)
          commit('setThumbnail')
          commit('setTitle')
          resolve(res)
        })
        .catch(err => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
