<template>
  <div>
    <template v-if="$vuetify.breakpoint.smAndDown">
      <v-btn
        v-if="!toggleSearch"
        style="position: absolute; right: 1rem; color: #A7A5B1"
        class="d-md-none px-0 mt-10"
        min-width="40px"
        text
        @click="toggleSearch = true"
      >
        <v-icon size="16px">search</v-icon>
      </v-btn>
      <div v-else class="mobileSearch">
        <v-card class="py-1">
          <v-btn
            text
            class="d-flex align-center"
            @click="toggleSearch = !toggleSearch"
          >
            <v-icon size="30px">navigate_before</v-icon>
            <span class="font-weight-semi-bold">Search</span>
          </v-btn>
        </v-card>
        <SearchDropdown
          v-if="toggleSearch"
          :isMobile="true"
          style="position:relative"
          @blur="toggleSearch = false"
        />
      </div>
    </template>
    <h1 class="fz-28 dark-text font-weight-semi-bold px-5 px-md-0 mobile">Good morning, {{ currentUser.firstName }}</h1>
    <h2 class="light-text fz-26 font-weight-regular mb-8 px-5 px-md-0 mobile">Here’s what’s happening today</h2>
    <div class="d-flex mb-10 px-5 px-md-0 mobile">
      <div class="mr-6">
            <div class="body-2 light-text">Today's Total Calls</div>
            <div class="text-h2 dark-text mt-n3 fz-26">{{ dashboardCall.total}}</div>
          </div>
          <div>
            <div class="body-2 light-text">Today’s Total Messages</div>
            <div class="text-h2 dark-text mt-n3 fz-26">{{ dashboardSMS.total }}</div>
          </div>
          <div class="ml-6">
            <div class="body-2 light-text">Today’s Total Voicemails</div>
            <div class="text-h2 dark-text mt-n3 fz-26">{{ dashboardVoicemails.total }}</div>
          </div>
    </div>
    <v-card class="pa-5 fz-14 mb-5 ">
      <div class="font-weight-semi-bold fz-16 mb-2">Status</div>
      <div class="mt-2">{{ notice }}</div>
      <!-- <div v-if="!statusEdit">
        <v-btn
          @click="editStatus"
          outlined
          min-width="57px"
          height="30px"
          class="text-capitalize grey-button br-3 align-self-center mt-4"
        >Edit
        </v-btn>
      </div>
      <template v-else>
        <v-textarea
          outlined
          height="80"
          class="mb-4"
          hide-details
          v-model="newStatus"
        />
        <v-btn
          @click="updateStatus"
          outlined
          height="30px"
          class="text-capitalize violet-button br-3 mr-2"
        >
          Save
        </v-btn>
        <v-btn
          @click="cancelStatusUpdate"
          outlined
          height="30px"
          class="text-capitalize grey-button br-3"
        >
          Cancel
        </v-btn>
      </template> -->
    </v-card>
    <card-collapse
      title="Missed calls"
      link="/history"
      :list="dashboard.missedCalls"
    >
      <template v-slot:subtitle>
        {{ dashboard.missedCalls.length || 'No' }} missed calls since your last visit
      </template>
      <template v-slot="{ item }">
        <v-list-item-avatar>
          <v-img
            :alt="`${item.name} avatar`"
            :src="item.avatar + '?random=' + item.name.length"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="fz-14">{{item.name}}</v-list-item-title>

          <v-list-item-subtitle>{{item.phone}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="d-flex flex-row align-center">
          <span class="mx-1 grey--text text--darken-2 d-none d-sm-flex flex-column flex-sm-row flex-md-column flex-lg-row"><span class="mr-2">{{ item.date }}</span><span>{{ item.time }}</span></span>
          <v-btn
            class="grey-button br-3 text-none mx-3 px-3"
            height="36px"
            min-width="30px"
            @click="callBack"
          >
            <v-icon color="#919EAB" class="mr-1">phone</v-icon>
            <span class="d-none d-sm-block d-md-none d-lg-block">Call back</span>
          </v-btn>

          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="{name: 'Chat'}"
                class="grey-button br-3 px-0"
                height="36px"
                min-width="44"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#919EAB">message</v-icon>
              </v-btn>
            </template>
            <span>Send message</span>
          </v-tooltip>
        </v-list-item-action>
      </template>
    </card-collapse>
    <card-collapse
      title="Unread messages"
      link="/history"
      :list="dashboard.unreadMessages"
    >
      <template v-slot:subtitle>
        {{ dashboard.unreadMessages.length || 'No' }} unread messages since your last visit
        <br/>
        <v-btn
          v-if="!dashboard.unreadMessages.length"
          class="grey-button mt-5 mb-n2"
          to="/voicemails"
        >
          <v-icon color="#919EAB">message</v-icon>
          New message
        </v-btn>
      </template>
      <template v-slot="{ item }">
        <v-list-item-avatar>
          <v-img
            :alt="`${item.ani} avatar`"
            :src="`https://picsum.photos/200?random=${Math.random(10)*100/10}`"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-if="!item.caller_first_name && !item.caller_last_name" class="fz-14">{{ item.ani }}</v-list-item-title>
          <v-list-item-title v-else class="fz-14">{{ item.caller_first_name }} {{ item.caller_last_name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.text }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="d-flex flex-row align-center">
          <v-btn
            class="grey-button br-3 text-none mx-3"
            height="36px"
            @click="openChat(item)"
          >
            <v-icon color="#919EAB" class="mr-1">message</v-icon>
            Message
          </v-btn>

          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="grey-button br-3 px-0"
                height="36px"
                min-width="44"
                v-bind="attrs"
                v-on="on"
                @click="callBack"
              >
                <v-icon color="#919EAB">phone</v-icon>
              </v-btn>
            </template>
            <span>Voice call</span>
          </v-tooltip>
        </v-list-item-action>
      </template>
    </card-collapse>
    <card-collapse
      title="Voicemail"
      subtitle="new records"
      link="/voicemails"
      :list="dashboard.voicemails"
      show-list
    >
      <template v-slot:subtitle>
        {{ dashboard.voicemails.length || 'No' }} new {{ dashboard.voicemails.length !== 1 ? 'records' : 'record' }}
        <br/>
        <v-btn
          v-if="!dashboard.voicemails.length"
          class="grey-button mt-5 mb-n2"
          to="/voicemails"
        >
          <v-icon color="#919EAB">voicemail</v-icon>
          Go to all voicemail
        </v-btn>
      </template>
      <template v-slot="{ list }">
        <voicemail-component :voicemails="list" />
      </template>
    </card-collapse>
    <card-collapse
      v-if="$store.state.auth.currentUser && $store.state.auth.currentUser.role === 'account_admin'"
      title="Queues"
      link="/queues"
      :list="dashboard.queues"
    >
      <template v-slot:subtitle>
        {{ queuesNumber || 'No' }} people in queues
      </template>
      <template v-slot="{ item }">
        <div @click="mailboxDialog = true" style="width: 100%">
          <queue-item :item="item" class="mx-n5" noLink/>
        </div>
      </template>
    </card-collapse>
    <card-collapse
      v-if="$store.state.auth.currentUser"
      title="Active Calls"
      :list="dashboard.activeCalls"
    >
      <template v-slot:subtitle>
        {{ dashboard.activeCalls.length || 'No' }} active calls
      </template>
      <template v-slot="{ item }">
        <v-list-item-avatar>
          <v-img
            :alt="`${item.ani} avatar`"
            :src="'https://picsum.photos/200?random=' + item.ani"
          ></v-img>
          <v-icon color="white" style="position: absolute">phone</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="fz-14 font-weight-medium"
            style="color: #1D2428;"
          >
            <span class="mr-1">
              <span v-if="item.caller_first_name || item.caller_last_name">{{item.caller_first_name}} {{item.caller_last_name}}</span>
              <span v-else>{{item.ani}}</span>
            </span>
            >
            <span class="ml-1">
              <span v-if="item.callee_first_name || item.callee_last_name">{{item.callee_first_name}} {{item.callee_last_name}}</span>
              <span v-else>{{item.dnis}}</span>
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action class="d-flex flex-row align-center">
          <v-btn class="grey-button br-3 text-none" height="36px">
            <v-icon color="#919EAB" class="mr-1">group</v-icon>
            Participants
          </v-btn>
        </v-list-item-action>
      </template>
    </card-collapse>
    <SendViaEmail :dialog="sendEmailDialog" :sendEmailDialog.sync="sendEmailDialog"/>
    <SendAsMessage :dialog="sendMessageDialog" :sendMessageDialog.sync="sendMessageDialog"/>
    <TranscriptionModal :dialog="editDialog" :editDialog.sync="editDialog"/>
    <MailboxDialog :dialog="mailboxDialog" :mailboxDialog.sync="mailboxDialog"/>
  </div>
</template>

<script>
import CardCollapse from '@/components/CardCollapse'
import QueueItem from '@/components/QueueItem'
import TranscriptionModal from '@/components/TranscriptionModal'
import SendAsMessage from '@/components/SendAsMessage'
import SendViaEmail from '@/components/SendViaEmail'
import MailboxDialog from '@/components/MailboxDialog'
import SearchDropdown from '@/components/SearchDropdown'
import VoicemailComponent from '@/components/VoicemailComponent'
import { mapGetters, mapActions } from 'vuex'
import * as moment from 'moment'

export default {
  name: 'HomeUser',
  components: {
    QueueItem,
    CardCollapse,
    TranscriptionModal,
    SendAsMessage,
    SendViaEmail,
    MailboxDialog,
    SearchDropdown,
    VoicemailComponent
  },
  data: () => ({
    playedIndex: null,
    sendEmailDialog: false,
    mailboxDialog: false,
    sendMessageDialog: false,
    editDialog: null,
    statusEdit: false,
    newStatus: '',
    dashboard: {
      username: 'John',
      status: 'Many centuries ago, man was able to tell the time by looking at the sun and its position in the sky.',
      missedCalls: [/*
        {
          name: 'Iva Nash',
          phone: '195-654-4944',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Eric Waters',
          phone: '101-290-9975',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Jeffery Jimenez',
          phone: '169-851-8611',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: 'Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        },
        {
          name: '2222Craig Flores',
          phone: '255-295-6559',
          date: '08 Apr 2017',
          time: '01:52PM',
          avatar: 'https://picsum.photos/200'
        } */
      ],
      unreadMessages: [],
      voicemails: [],
      queues: [
        {
          title: 'Design mailbox',
          users: [
            {
              name: 'Rosa Ferguson',
              phone: '080-021-3014',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steve Nash',
              phone: '195-654-4944',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: true,
              avatar: 'https://picsum.photos/200'
            }
          ]
        },
        {
          title: 'Design mailbox',
          users: [
            {
              name: 'Rosa Ferguson',
              phone: '080-021-3014',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steve Nash',
              phone: '195-654-4944',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: true,
              avatar: 'https://picsum.photos/200'
            }
          ]
        },
        {
          title: 'Design mailbox',
          users: [
            {
              name: 'Rosa Ferguson',
              phone: '080-021-3014',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steve Nash',
              phone: '195-654-4944',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: true,
              avatar: 'https://picsum.photos/200'
            }
          ]
        },
        {
          title: 'Design mailbox',
          users: [
            {
              name: 'Rosa Ferguson',
              phone: '080-021-3014',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steve Nash',
              phone: '195-654-4944',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: true,
              avatar: 'https://picsum.photos/200'
            }
          ]
        },
        {
          title: 'Design mailbox',
          users: [
            {
              name: 'Rosa Ferguson',
              phone: '080-021-3014',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steve Nash',
              phone: '195-654-4944',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: true,
              avatar: 'https://picsum.photos/200'
            }
          ]
        },
        {
          title: 'Marketing mailbox',
          users: [
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Steph Curry',
              phone: '101-290-9975',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Jessica Alba',
              phone: '169-851-8611',
              lastYours: true,
              unreadMessage: false,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            },
            {
              name: 'Moses Malone',
              phone: '255-295-6559',
              lastYours: true,
              unreadMessage: true,
              missedCall: false,
              avatar: 'https://picsum.photos/200'
            }
          ]
        }
      ],
      activeCalls: []
    },
    dashboardCall: {
      total: 0
    },
    dashboardSMS: {
      total: 0
    },
    dashboardVoicemails: {
      total: 0
    },
    toggleSearch: false,
    mailboxList: []
  }),
  computed: {
    ...mapGetters([
      'currentUser',
      'userAccount'
    ]),
    queuesNumber () {
      let result = 0
      this.dashboard.queues.forEach(q => {
        result += q.users.length
      })
      return result
    },
    notice () {
      return this.userAccount ? this.userAccount.notice : ''
    }
  },
  async created () {
    this.getData()
    this.getRecordings()
    this.getCallLogs()
    this.getSmsLogs()
    this.loadLiveCalls()
    this.loadUnreadMessages()
    await this.loadMailboxes()
    this.getVoicemailsHandler()
  },
  methods: {
    ...mapActions([
      'getAccountData', 'getDashboardCall', 'getDashboardSMS', 'setUnreadSms',
      'getVoicemails', 'getMailboxes', 'getLiveCalls', 'getMessages'
    ]),
    async getData () {
      try {
        const { data } = await this.getAccountData()
        console.log(data, 'getData')
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadMailboxes () {
      try {
        const { data } = await this.getMailboxes({
          take: 9999
        })
        this.mailboxList = data.mailboxList
      } catch (err) {
        throw new Error(err)
      }
    },
    async getVoicemailsHandler () {
      try {
        const today = moment(new Date()).format('YYYY-MM-DD')
        const params = {
          startDate: today,
          endDate: today,
          orderBy: 'created_at',
          orderType: 'DESC'
        }
        const { data } = await this.getVoicemails(params)
        this.dashboard.voicemails = data.data.map(item => ({
          ...item,
          mailbox_name: this.getMailboxName(item.mailbox_uuid)
        }))
        this.dashboardVoicemails.total = data.total
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadLiveCalls () {
      try {
        const { data } = await this.getLiveCalls({
          take: 999
        })

        this.dashboard.activeCalls = data.payload.items
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadUnreadMessages () {
      try {
        this.loading = true
        const params = {
          smsDir: 'all',
          orderType: 'DESC',
          orderBy: 'created_at',
          unread: true
        }
        const { data } = await this.getMessages(params)
        if (!data || !data.payload || !data.payload.items) return
        this.dashboard.unreadMessages = data.payload.items
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    getMailboxName (uuid) {
      const mailbox = this.mailboxList.find(e => e.uuid === uuid)
      return mailbox ? mailbox.name : ''
    },
    getRecordings () {
      this.$store.dispatch('getRecordings')
        .then((res) => {
          this.voicemails = res.data.recordingList
        }).catch(err => {
          throw new Error(err)
        })
    },
    onPauseChange (index, event) {
      if (index !== this.playedIndex && this.playedIndex !== null) {
        this.$refs.trackDashboard[this.playedIndex].togglePlaying()
      }
      this.playedIndex = event ? null : index
    },
    editStatus () {
      this.statusEdit = true
      this.newStatus = this.dashboard.status
    },
    updateStatus () {
      this.$store
        .dispatch('editNotice', { notice: this.newStatus })
        .then((res) => {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
      this.dashboard.status = this.newStatus
      this.statusEdit = false
    },
    cancelStatusUpdate () {
      this.newStatus = this.dashboard.status
      this.statusEdit = false
    },
    callBack () {
      this.$store.dispatch('components/keypadStatus', true)
    },
    async getCallLogs () {
      try {
        const today = moment(new Date()).format('YYYY-MM-DD')
        const params = {
          startDate: today,
          endDate: today,
          callDir: 'all',
          groupBy: 'day'
        }
        const { data } = await this.getDashboardCall(params)

        if (!data || !data.payload || !data.payload.length) return
        this.dashboardCall.total = data.payload[0].total_attempt
      } catch (err) {
        throw new Error(err)
      }
    },
    async getSmsLogs () {
      try {
        const today = moment(new Date()).format('YYYY-MM-DD')
        const params = {
          startDate: today,
          endDate: today,
          smsDir: 'all',
          groupBy: 'day'
        }

        const { data } = await this.getDashboardSMS(params)

        if (!data || !data.payload || !data.payload.length) return
        this.dashboardSMS.total = data.payload[0].total || 0
      } catch (err) {
        throw new Error(err)
      }
    },
    async openChat (item) {
      try {
        await this.setUnreadSms(item.uuid)
        this.$router.push({ name: 'Chat', query: { n: item.ani } })
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 450px) {
  h1.mobile {
    font-size: 6vw !important;
  }
  h2.mobile {
    font-size: 5vw !important;
  }
  div.mobile {
    width: 100% !important;
    justify-content: space-between;
  }
  div.mobile .fz-14 {
    font-size: 3vw !important;
  }
  div.mobile .fz-26 {
    font-size: 6vw !important;
  }
}
</style>
